export default {
  "general": {
    "profile": {
      "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضغط هنا لتحميل البروفايل"])}
    },
    "seeMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المزيد"])},
    "siteName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jump sa"])},
    "home": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jump sa"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شركة سعودية رائدة في مجال الدعاية والإعلان والإنتاج الفني"])},
      "keys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شركة جمب, شركة jump, خدمات ابداعية, شركة انتاج فني, شركات الدعايه والاعلان, شركة انتاج اعلامي, انتاج فيديو, انتاج موشن جرافيك, وكالة دعاية واعلان, تسويق الكتروني, تسويق رقمي, شركات تسويق في الرياض, شركات التسويق في السعودية, للدعاية والاعلان, تصميم هوية, تصميم فيديو, تصميم لوجو, تصميم موشن جرافيك, تعليق صوتي,  "])}
    },
    "video": {
      "title": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Jump sa"]), _normalize(["Video Production "])])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انتاج فيديو احترافي، فيديو مصور ، كتابة السيناريو، رسم الستوري بورد، تعيين الممثلين والكومبارس، اختيار مودل ممثلين، تحرير الفيديو ، التصوير باستخدام احدث التقنيات ، تصوير على خلفية خضراء، تصوير كروما، تاجير مواقع التصوير، تصوير في الرياض، كتابة محتوى اعلاني، تعليق صوتي، اختيار المواهب"])},
      "keys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انتاج فيديوهات, انتاج فيديو, انتاج فني, شركة انتاج فني, شركات الانتاج الفني, انتاج الفيديوهات, تصميم مقاطع فيديو, تصميم فيديو اعلاني, مونتاج فيديو احترافي, تصميم فيديو, تصميم فيديو احترافي, "])}
    },
    "motion": {
      "title": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Jump sa"]), _normalize(["Motion Graphics "])])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انتاج موشن جرافيك من كتابة السيناريو، إنتاج انيميشن ، تصميم موشن ، تصميم غرافيك، ورسم الستوري بورد، تسجيل التعليق الصوتي، تصميم المشاهد، التحريك والانيميشن، تسجيل التعليق الصوتي، الموسيقى والمؤثرات"])},
      "keys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تصميم فيديو موشن, تصميم موشن جرافيك, انتاج موشن جرافيك, تصميم فيديو جرافيك, انتاج انيميشن, تصميم انيميشن, اعلانات موشن جرافيك, تصميم موشنجرافيك, انتاج موشن غرافيك, انتاج موشن قرافيك, "])}
    },
    "branding": {
      "title": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Jump sa"]), _normalize(["Branding "])])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["صمم هويتك التجارية، هويتك البصرية ، تصميم اللوجو، تصميم لوجو تطبيقات الهوية، تصميم كرت العمل، أوراق رسمية، تصميم بروفايل، تصميم بريزنتيشن"])},
      "keys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تصميم هوية تجارية, تصميم علامة تجارية, تصميم هوية, تصميم لوجو, تصميم لوجوات, تصميم لوجوهات, تطبيقات الهوية, تصميم بروفايل, تصميم برزنتيشن, تصميم بريزنتيشن, ارشادات الهوية,  "])}
    }
  },
  "nav": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرئيسية"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من نحن"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اتصل بنا"])},
    "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إنتاج الفيديو"])},
    "motion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرسوم المتحركة"])},
    "branding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تصميم الهوية"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حمل الملف التعريفي"])},
    "lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])}
  },
  "section": {
    "Video Production": (ctx) => {const { normalize: _normalize, linked: _linked } = ctx;return _normalize([_linked("nav.video")])},
    "Motion Graphics": (ctx) => {const { normalize: _normalize, linked: _linked } = ctx;return _normalize([_linked("nav.motion")])},
    "Behind the Scenes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خلف الكواليس"])},
    "Branding": (ctx) => {const { normalize: _normalize, linked: _linked } = ctx;return _normalize([_linked("nav.branding")])},
    "Bvv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خلف الكواليس"])}
  },
  "about": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من نحن:"])},
    "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span class='text-red-500'>JUMP</span> هي شركة سعودية رائدة في مجال الدعاية والإعلان والإنتاج الفني، بقاعدة عملاء في الخليج و الشرق الأوسط ، نقدم مجموعة واسعة من الخدمات الإبداعية مثل كتابة السيناريوهات والأفكار، إنتاج و تصوير الإعلانات، رسم الستوري بورد، الانيميشن والموشن جرافيك، تصميم الهوية، التصوير بالدرون وغيره الكثير."])}
  },
  "services": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خدماتنا"])},
    "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إذا كنت تريد إنتاج إعلانات للتليفزيون أو الإنترنت، أو فيلماً وثائقياً لشركتك، أو اردت انتاج الرسوم المتحركة 'موشن جرافيك'، او تصميم هويتك التجارية فقد وصلت للمكان المناسب! فنحن ننتج كل ما سبق بجودة رائعة و محتوى جذاب لا يمكن نسيانه."])}
  },
  "video": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مراحل انتاج الفيديو"])},
    "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["slider/video-ar.svg"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عندما يآتي الامر لانتاج الفيديوهات كل ما عليك هو إخبارنا بأهدافك وسوف نقوم بتحويلها إلى محتوى جذاب ومشاهد رائعة تسهل وصول رسائلك إلى قلب جمهورك! تجد بالاسفل امثلة على فيديوهات مصورة من انتاجنا من كتابة الفكرة والسيناريو حتى المونتاج والتسليم."])},
    "stages": [
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كتابة السيناريو"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رسم الستوري بورد"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختيار الممثلين"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختيار اللوكيشن"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التصوير"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل التعليق الصوتي"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المونتاج وإضافة الموسيقى"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة الجرافيك / المؤثرات البصرية"])}
      }
    ]
  },
  "motion": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مراحل انتاج الموشن جرافيك"])},
    "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["slider/motion-ar.svg"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأنيميشن أو الموشن جرافيك هي طريقة رائعة لزيادة وعي الجمهور بعلامتك التجارية، أو تعريفهم بمنتج بعينه، أو تحويل زوار موقعك الإلكتروني إلى زبائن.. اجذبهم بطريقة مبدعة! تجد بالاسفل أمثلة على موشن جرافيك من انتاجنا من كتابة الفكرة والسيناريو حتى التحريك والتسليم."])},
    "stages": [
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كتابة السيناريو"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رسم الستوري بورد"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل التعليق الصوتي"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التحريك"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة الموسيقى والمؤثرات"])}
      }
    ]
  },
  "branding": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مراحل تصميم الهوية التجارية"])},
    "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["slider/branding-ar.svg"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اللوجو او الهوية هي أول ما يلاحظه جمهورك حتى قبل أن يتعرف على خدماتك بشكل كامل، تصميم علامة تجارية ابداعية  متوافقة مع هويتك التجارية هو أمر حتمي لجذب عميلك المستهدف! تجد بالأسفل أمثلة على تصميم هويات تجارية مع تطبيقاتها"])},
    "stages": [
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يقوم العميل بتعبئة استبيان بسيط عن منتجه/شركته"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تصميم اكثر من خيار للوجو"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تصميم تطبيقات الهوية"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تصميم الدليل الإرشادي للهوية"])}
      }
    ]
  },
  "contact": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اتصل بنا"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are always ready to help you. Feel free to contact us for any questions or inquiries you may have."])},
    "addess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Po.box 826 Riyadh 11421 KSA"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+966 540 233 695"])},
    "form": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم بالكامل"])},
      "namePlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل اسمك هنا"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البريد الالكتروني"])},
      "emailPlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ضع بريدك الالكتروني هنا"])},
      "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان الرسالة"])},
      "subjectPlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ادخل عنوان الرسالة هنا"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رسالتك"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إرسال"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تم استلام رسالتك بنجاح، سنتواصل معكم بعد قليل"])}
    }
  }
}