export default {
  "general": {
    "profile": {
      "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click here to download the profile"])}
    },
    "seeMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See More"])},
    "siteName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jump sa"])},
    "home": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jump sa - Home"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JUMP is the best advertising agency, and production company in KSA, Riyadh, Jeddah, Eastern Province, Dammam       We provide creative services such as script writing, Video production, graphic design, storyboard drawing, animation production, motion graphics, branding and identity production, logo design, photography, drone photography, drone filming"])},
      "keys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jump company,Jump advertising, Creative agency,Video Production,Production Company,Production House,Advertising Agency,Motion Graphics production,Marketing Agency,Ad company,Ad agency riyadh,Branding company,Logo design company,Advertising company,Video Equipment rental,Filming company,Advertising production,Voice over production,VO production,"])}
    },
    "video": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video Production - jump sa "])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professional video production, videographer, scriptwriting, storyboard drawing, actors casting, cast, models,, video editing, photography, Chroma filming, Green screen filming, Green screen location rental, filming in Riyadh"])},
      "keys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video production, Production house, Ad production, Advertising production, Video montage, Filming company, Professional video, Casting, Models, Actors, Chroma filming, Green screen filming, Green screen video,  "])}
    },
    "motion": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motion Graphics - jump sa "])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motion graphics production, animation production, storyboard drawing, animation, voice over recording, music and sound effects"])},
      "keys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motion graphics, Animation production, Character Animation, Motiongraphics, Motion Graphics Production, Script writing, Voice over, music and sound effects, "])}
    },
    "branding": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branding - jump sa "])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Design your brand identity, design logo, branding booklet, brand guidelines, design office stationary, design company profile"])},
      "keys": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branding, Branding agency, Brand and Identity design, Logo design, Identity design, Profile design, Presentation design, brand guidelines,  "])}
    }
  },
  "nav": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About us"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us"])},
    "video": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video Production"])},
    "motion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motion Graphics"])},
    "behind": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Behind the Scenes"])},
    "branding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branding"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["download profile"])},
    "lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عربي"])}
  },
  "section": {
    "Video Production": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video Production"])},
    "Motion Graphics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motion Graphics"])},
    "Behind the Scenes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Behind the Scenes"])},
    "Branding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branding"])},
    "Bvv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Behind the Scenes"])}
  },
  "about": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About us"])},
    "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span class='text-red-500'>JUMP</span> is a pioneering Saudi Advertising and production company with a wide client base in the Gulf and the Middle east region.<br>       We offer a diverse range of services, like creative copywriting, brainstorming, storyboarding, animation/motion graphics, video production, and branding."])}
  },
  "services": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Services"])},
    "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Whether you want to produce a TV or web commercial, a documentary about your company, a motion-graphic video, or your unique Identity and branding, you are in the right place! We create all the above to highest quality which will make your content unforgettable. "])}
  },
  "video": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Video Production Stages"])},
    "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["slider/video.png"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When it comes to video production all you have to do is explain your objectives to us and we will transform your vision into compelling content and videos that can easily reach the hearts of your audience. Find below some samples of our work, thoroughly produced by Jump from idea to final cut."])},
    "stages": [
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scriptwriting"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Storyboarding"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Casting"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location Scouting"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shooting"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voice-over Recording"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editing and Sound Design"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graphics / VFX"])}
      }
    ]
  },
  "motion": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motion Graphics Production Stages"])},
    "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["slider/motion.png"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Animation or motion graphics is a marvellous and cost effective way to increase your brand awareness, introduce your products, or transform your website visitors into clients.. Entice them in a creative way! Find below some samples of our work, thoroughly produced by Jump from idea to final cut."])},
    "stages": [
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scriptwriting"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Storyboarding"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voice-over Recording"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Animation"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adding Music and Effects"])}
      }
    ]
  },
  "branding": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Branding Stages"])},
    "caption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["slider/branding.png"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your logo or visual identity is the first thing that your audience will notice even before they get to know about your services. Creating a creative identity that is 100% in sync with your business persona is a sure way to attract your target audience. Find below samples of our branding work."])},
    "stages": [
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The client takes a survey about his/her business or product"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We create a number of logo options"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applications"])}
      },
      {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Creating identity Guide"])}
      }
    ]
  },
  "contact": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are always ready to help you. Feel free to contact us for any questions or inquiries you may have."])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Po.box 826 Riyadh 11421 KSA"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+966 540 233 695"])},
    "form": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full Name"])},
      "namePlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your name"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "emailPlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your email"])},
      "subject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subject"])},
      "subjectPlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message subject"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Message"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your message has been sent successfully."])}
    }
  }
}