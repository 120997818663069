import http from "@/http-common";

class WorksDataService {
  getAll(): Promise<any> {
    return http.get("/posts");
  }

  get(id: any): Promise<any> {
    return http.get(`/works/${id}`);
  }

  findByTitle(title: string): Promise<any> {
    return http.get(`/works?title=${title}`);
  }
}

export default new WorksDataService();