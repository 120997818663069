<template>
<div>
   <!-- Works -->
   <section class="flex flex-col w-full h-full antialiased bg-black  ">
      <Header></Header>
      <slider class="xl:-mt-20" :cat="workCat"></slider>
    </section>
    <div>
       <section class="py-10 bg-black">
        <works :name="pageTitle" :cat="workCat" ></works>
      </section>
      </div>

    <light-box></light-box>
  <contact-us></contact-us>
</div>

</template>

<script>
import Header from '@/components/header.vue'
import Slider from '@/components/slider.vue'

import Works from '@/components/works.vue'

import ContactUs from '@/components/contactUs.vue'

import { defineComponent } from 'vue'
import { useI18n } from 'vue-i18n'
import { useMeta } from 'vue-meta'

export default defineComponent({
  name: 'Motion',
  props: {
    pageTitle: {
      type: String,
      default: ''
    },
    workCat: {
      type: Number
    }

  },
  setup () {
    const { t } = useI18n({})
    const title = t('general.motion.title')
    const desc = t('general.motion.description')
    const keys = t('general.motion.keys')

    useMeta({
      title: `${title}`,
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: `${desc}`
        },
        {
          vmid: 'keywords',
          name: 'keywords',
          content: `${keys}`
        },
        { property: 'og:title', content: `${title}` },
        { property: 'og:site_name', content: 'Jump sa' },
        { property: 'og:type', content: 'website' },
        { name: 'robots', content: 'index,follow' }
      ]

    })
  },
  components: {
    ContactUs,
    Works,
    Header,
    Slider
  }

})
</script>