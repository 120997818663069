import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import i18n, { selectedLocale } from '@/i18n'
// Create a new store instance.
const store = createStore({
  state: {
    // i18n
    locale: selectedLocale,
    count: 1,
    isShow: false,
    isLightBoxShow: false,

    videoWorks: [{
      name: 'Video Production',
      cat: '1',
      order: 1,
      ref: 'video-production',
      sub: [
        {
          name: 'Video Production',
          cat: '1',
          ref: 'video-production',
          parent: '1'

        },
        {
          name: 'Behind the Scenes',
          cat: '2',
          ref: 'video-production',
          parent: '1'
        }]
    }],
    motionWorks: [{
      name: 'Motion Graphics',
      cat: 3,
      order: 2,
      ref: 'motion-graphics',
      sub: [{
        name: 'Motion Graphics',
        cat: 3,
        ref: 'motion-graphics',
        parent: 3
      }]

    }],
    brandingWorks: [{
      name: 'Branding',
      cat: 4,
      order: 4,
      ref: 'branding'
    }],
    worksCat: [{
      name: 'Video Production',
      cat: 1,
      order: 1,
      ref: 'video-production',
      sub: [
        {
          name: 'Video Production',
          cat: 1,
          ref: 'video-production',
          parent: 1

        },
        {
          name: 'Behind the Scenes',
          cat: 2,
          ref: 'video-production',
          parent: 1
        }]
    },
    {
      name: 'Behind the Scenes',
      cat: 2,
      order: 3,
      ref: 'video-production',
      sub: [{
        name: 'Behind the Scenes',
        cat: 2,
        ref: 'behind-the-scenes',
        parent: 2
      }]
    },
    {
      name: 'Motion Graphics',
      cat: 3,
      order: 2,
      ref: 'motion-graphics',
      sub: [{
        name: 'Motion Graphics',
        cat: 3,
        ref: 'motion-graphics',
        parent: 3
      }]
    },
    {
      name: 'Branding',
      cat: 4,
      order: 4,
      ref: 'branding',
      sub: [{
        name: 'Branding',
        cat: 4,
        ref: 'branding',
        parent: 4
      }]
    }

    ],
    videoId: 'Q7ElixcjiUk', // defult
    main_image: '',
    sliderVideo: ''

  },
  mutations: {
    // i18n
    updateLocale (state, newLocale) {
      state.locale = newLocale
    },

    increment (state) {
      state.count++
    },
    showModal (state, id) {
      state.isShow = true
      if (id.length >= 0 || id !== null) {
        state.videoId = id
      }
    },
    showLightBox (state, payload) {
      state.isLightBoxShow = true
      if (payload.mainImage === null || payload.mainImage.length === 0) {
        if (payload.thumbImage !== null && payload.thumbImage.length >= 1) {
          state.main_image = payload.thumbImage
        }
      } else {
        state.main_image = payload.mainImage
      }
    },
    closeLightBox (state) {
      state.isLightBoxShow = false
    },
    closeModal (state) {
      state.isShow = false
    },
    setVideoId (state, id) {
      if (id !== null) {
        state.videoId = id
      }
    },
    setSliderVideo (state, id) {
      if (id !== null) {
        state.sliderVideo = id
      }
    }

  },
  actions: {
    // i18n
    changeLocale ({ commit }, newLocale) {
      i18n.global.locale = newLocale // important!
      commit('updateLocale', newLocale)
    }

  },
  getters: {
    getSliderVideo (state, id) {
      return state.sliderVideo
    },
    getLocale (state, id) {
      return state.locale
    }

  },
  modules: {

  },
  plugins: [createPersistedState()]

})

export default store