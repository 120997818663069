<template>
  <div class="absolute flex-wrap z-50 h-0  mx-auto max-w-7xl top-0 inset-x-0 " style="">
    <!-- nav with logo -->
    <div
      class="container flex  rtl:flex-row-reverse md:rtl:flex-row flex-wrap items-center justify-between py-0 mx-auto  max-w-2xl md:max-w-4xl xl:max-w-6xl">
      <!-- Logo -->
      <router-link :to="{name: 'Home'}"
        class="flex items-start pl-5 rtl:pr-5 order-first font-medium text-gray-50  md:order-none md:w-auto md:pb-5 title-font md:justify-center md:m-0">
        <img class="max-h-20 md:max-h-24 " :src="require(`@/assets/icons/logo.jpg`)" alt="Jump sa logo">
      </router-link>
      <!-- Logo -->
      <div class="right-5  top-5  md:hidden z-50" :class="{'absolute': !showMenu, 'fixed': showMenu}">
          <button class="text-3xl px-0 py-1 text-red-500 block lg:hidden outline-none focus:outline-none" type="button"
            @click="toggleNavbar()">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10" fill="none" viewBox="0 0 24 24"
              stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </button>
      </div>
      <div class=" w-screen  absolute top-0 md:top-auto  md:relative flex-col  md:w-auto md:h-auto md:flex md:flex-row">

        <div :class="{'hidden': !showMenu, 'flex items-center w-screen h-screen fixed bg-cover bg-black': showMenu}"
          class=" md:bg-transparent md:right-auto md:top-auto md:relative md:flex md:flex-grow md:items-center">
          <nav class="flex flex-col space-y-4 text-2xl rtl:text-md px-5 py-5 md:w-auto md:space-y-0 md:flex-row  rtl:font-cairo font-oswald  font-normal md:font-medium  rtl:tracking-wide tracking-widest   uppercase md:text-sm md:text-md
                                    md:pt-0 md:mb-0 md:border-b-0 md:pb-0">
            <router-link @click="closeNavbar();"  :to="{name: 'Home', params: { lang: this.$i18n.locale }}" tag="a" exact class=" border-b-2 border-gray-500 w-full md:w-auto md:border-0 md:px-auto py-1 mr-6 rtl:ml-6 leading-6 text-gray-50 hover:text-red-500">{{ $t('nav.home')}}</router-link>
            <router-link  @click="closeNavbar();" :to="{name: 'About', params: { lang: this.$i18n.locale }}" tag="a"  class=" border-b-2 border-gray-500 w-full md:w-auto md:border-0 md:px-auto py-1 mr-6 rtl:ml-6 leading-6 text-gray-50 hover:text-red-500">{{ $t('nav.about')}} </router-link>
            <router-link  @click="closeNavbar();" :to="{name: 'video-production' , params: { lang: this.$i18n.locale }}" tag="a" exact class=" border-b-2 border-gray-500 w-full md:w-auto md:border-0 md:px-auto py-1 mr-6 rtl:ml-6 leading-6 text-gray-50 hover:text-red-500">{{ $t('nav.video')}} </router-link>
            <router-link  @click="closeNavbar();" :to="{name: 'motion-graphics' , params: { lang: this.$i18n.locale }}" tag="a"  class=" border-b-2 border-gray-500 w-full md:w-auto md:border-0 md:px-auto py-1 mr-6 rtl:ml-6 leading-6 text-gray-50 hover:text-red-500">{{ $t('nav.motion')}}</router-link>

            <router-link @click="closeNavbar()" :to="{name: 'branding' , params: { lang: this.$i18n.locale }}" tag="a" class=" border-b-2 border-gray-500 w-full md:w-auto md:border-0 md:px-auto py-1 mr-6 rtl:ml-6 leading-6 text-gray-50 hover:text-red-500">{{ $t('nav.branding')}} </router-link>
            <router-link @click="closeNavbar()" :to="{name: 'portfolio' , params: { lang: this.$i18n.locale }}" tag="a" class=" border-b-2 border-gray-500 w-full md:w-auto md:border-0 md:px-auto py-1 mr-6 rtl:ml-6 leading-6 text-gray-50 hover:text-red-500">{{ $t('nav.profile')}} </router-link>
            <router-link  @click="closeNavbar();"  :to="{name: 'Contact' , params: { lang: this.$i18n.locale }}" tag="a"  class=" border-b-2 border-gray-500 w-full md:w-auto md:border-0 md:px-auto py-1 mr-6 rtl:ml-6 leading-6 text-gray-50 hover:text-red-500">{{ $t('nav.contact')}} </router-link>
            <switch-lang></switch-lang>

          </nav>
        </div>
      </div>
    </div>
  </div>
</template>

<script lan="ts">

import SwitchLang from '@/components/SwitchLang.vue'
import i18n from '@/i18n'

export default {
  name: 'Header',
  data () {
    return {
      showMenu: false
    }
  },
  methods: {
    toggleNavbar: function () {
      this.showMenu = !this.showMenu
    },
    closeNavbar: function () {
      this.showMenu = false
    },
    goToHome () {
      this.$router.push('/')
    }

  },
  components: {
    SwitchLang,
    i18n,
  }

}
</script>

<style lang="css">
 nav a:hover {
  color: red;
 }
 nav a.router-link-active:last-child,
 nav a.router-link-exact-active {
   border-bottom: 2px solid #d61900;
   cursor: pointer;
 }
</style>