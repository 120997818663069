import axios, { AxiosInstance } from "axios";

const apiClient: AxiosInstance = axios.create({
    baseURL: "https://jumpnew.jump.sa/api/",
    // baseURL: "http://127.0.0.1:8000/api/",
    headers: {
      "Content-type": "application/json",
    },
  });

  export default apiClient;