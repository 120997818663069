<template>
  <div class="py-1">
    <span class=" cursor-pointer  text-white font-cairo text-2xl font-normal tracking-normal w-full md:text-base md:w-auto md:border-0 md:px-auto py-1 mr-6 rtl:ml-6 leading-6  hover:text-red-500" v-if="$i18n.locale === 'en'" @click="changeLang('ar')" >عربي</span>
    <span class=" cursor-pointer text-2xl text-white font-oswald font-normal w-full md:text-base md:w-auto md:border-0 md:px-auto py-1 mr-6 rtl:ml-6 leading-6  hover:text-red-500" v-else-if="$i18n.locale === 'ar'" @click="changeLang('en')" >English</span>

    <!-- <select v-model="lang">
      <option
        v-for="(lang, i) in languageArray"
        :key="`lang${i}`"
        :value="lang"
      >
        {{ lang }}
      </option>
    </select> -->
  </div>
</template>
<script>
import { languages, i18n } from '@/i18n'
export default {
  data () {
    return {
      languageArray: languages
    }
  },

  methods: {
    changeLang: function (val, locale) {
      this.$store.dispatch('changeLocale', val)
      const htmlEl = document.querySelector('html')

      if (this.$i18n.locale !== val) {
        this.$i18n.locale = val
      }

      if (val === 'en') {
        htmlEl.setAttribute('dir', 'ltr')
      } else {
        htmlEl.setAttribute('dir', 'rtl')
      }
      htmlEl.setAttribute('lang', `/${val}`)
      this.$router.push(`/${val}`)
      this.showMenu = false
    },
    switchLocale (locale) {
      if (this.$i18n.locale !== locale) {
        this.$i18n.locale = locale
        const to = this.$router.resolve({ params: { locale } })
        this.$router.push(to.location)
      }
    }
  },
  computed: {
    lang: {
      get: function () {
        // return this.$store.state.locale
        return this.$i18n.locale
      },
      set: function (newValue) {
        this.$store.dispatch('changeLocale', newValue)
        const htmlEl = document.querySelector('html')
        if (newValue === 'en') {
          htmlEl.setAttribute('dir', 'ltr')
          htmlEl.setAttribute('lang', 'en')
        } else {
          htmlEl.setAttribute('dir', 'rtl')
          htmlEl.setAttribute('lang', 'ar')
        }
      }
    }
  }
}
</script>